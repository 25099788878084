import { createBrowserRouter, Navigate } from "react-router-dom";
import { AddressChangeForm } from "./AddressChangeForm/AddressChangeForm";
import { AuthChecker } from "./common/components/AuthChecker";
import { FormLayout } from "./FormLayout";
import { InquiryForm } from "./InquiryForm";
import { OtlForm } from "./OtlForm";
import { RegistrationForm } from "./RegistrationForm";
import { SuccessPage } from "./ResultPages";
import { ProjectJewelForm } from "./ProjectJewelForm";
import { TravelRequest } from "./TravelRequest";
import { ApprovedPage } from "./ResultPages/ApprovedPage";
import { CfiForm } from "./CfiForm";
import { MaligaksatFamilyInfoForm } from "./MaligaksatFamilyInfoForm/MaligaksatFamilyInfoForm";
import React from "react";
import { NotFoundPage } from "./NotFoundPage";
import { AnniversaryForm } from "./AnniversaryForm";
import { CdcForm } from "./CdcForm/CdcForm";
import { IHAPForm } from "./IHAPForm/IHAPForm";
import { CfiWinterClothingForm } from "./CfiWinterClothingForm";
import Dashboard from "./Internal/Dashboard";
import { CfiFoodProgramForm } from "./FoodsProgramForm";
import { BusinessListApplicationForm } from "./BusinessListApplicationForm/BusinessListApplicationForm";

interface FormDefinition {
  element: React.ReactNode;
  path: string;
  name: string;
}

export const externalForms: FormDefinition[] = [
  {
    name: "OTL",
    path: "/otl",
    element: <OtlForm />,
  },
  {
    name: "Registration",
    path: "/registration",
    element: <RegistrationForm />,
  },
  {
    name: "Address Change",
    path: "/address-change",
    element: <AddressChangeForm />,
  },
  {
    name: "Inquiry",
    path: "/inquiry",
    element: <InquiryForm />,
  },
  {
    name: "Project Jewel",
    path: "/project-jewel",
    element: <ProjectJewelForm />,
  },
  {
    name: "CFI",
    path: "/cfi",
    element: <CfiForm />,
  },
  {
    name: "CFI Winter Clothing",
    path: "/cfi-winter-clothing",
    element: <CfiWinterClothingForm />,
  },
  {
    name: "Inuvialuit Food Program",
    path: "/food-program",
    element: <CfiFoodProgramForm />,
  },
  {
    name: "MCFIS Family Info Form",
    path: "/maligaksat-family-info",
    element: <MaligaksatFamilyInfoForm />,
  },
  {
    name: "40th Anniversary",
    path: "/anniversary",
    element: <AnniversaryForm />,
  },
  {
    name: "CDC",
    path: "/cdc",
    element: <CdcForm />,
  },
  {
    name: "IHAP",
    path: "/ihap",
    element: <IHAPForm />,
  },
  {
    name: "Business List Application",
    path: "/ibl",
    element: <BusinessListApplicationForm />,
  },
];

export const internalForms: FormDefinition[] = [
  {
    name: "Travel Form",
    path: "travel-form",
    element: <TravelRequest />,
  },
];

const router = createBrowserRouter([
  {
    element: <FormLayout />,
    children: [
      ...externalForms.map((f) => ({
        path: f.path,
        element: f.element,
      })),
      {
        path: "/success",
        element: <SuccessPage />,
      },
      {
        path: "/approved",
        element: <ApprovedPage />,
      },
      {
        path: "/internal",
        element: <AuthChecker />,
        children: [
          {
            path: "dashboard",
            element: <Dashboard />,
          },

          ...internalForms.map((f) => ({
            path: f.path,
            element: f.element,
          })),
          {
            path: "travel-form/:id",
            element: <TravelRequest />,
          },
          {
            path: "*",
            element: <NotFoundPage internal />,
          },
        ],
      },
      {
        path: "/*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export { router };
