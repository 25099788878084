import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { CheckboxFormField } from "../common/components/FormFields";

interface BusinessListApplicationConsentFieldsProps {}

export function BusinessListApplicationConsentFields({}: BusinessListApplicationConsentFieldsProps) {
  return (
    <>
      <Box>
        <Typography variant="h6">Application Consent</Typography>
      </Box>
      <CheckboxFormField
        name="consentAccept"
        mustBeTrue
        label="The Inuvialuit Business List (IBL) is published online for public information. By checking this box, I acknowledge and consent to the business listing information being published online for viewing and access by the public."
      />
      <CheckboxFormField
        name="confirmNotApproval"
        mustBeTrue
        label="By checking this box, I declares that the above information is correct and that I am authorized to submit this application on behalf of the applicant."
      />
    </>
  );
}
