import React from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

interface BusinessListApplicationHeaderProps {}

export function BusinessListApplicationHeader({}: BusinessListApplicationHeaderProps) {
  return (
    <>
      <Box>
        <Typography variant="h4" paragraph>
          Business List Application Form
        </Typography>
        <Typography paragraph>
          Businesses applying to be included on IRC’s Inuvialuit Business List
          (IBL) must complete this application form. The list can be found on
          our{" "}
          <Link
            href={
              "http://www.irc.inuvialuit.com/business/inuvialuit-business-list-ibl"
            }
          >
            website
          </Link>
          {"."}
        </Typography>
      </Box>
    </>
  );
}
