import { Grid, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import {
  DateFormField,
  RadioFormField,
  TextFormField,
} from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import { Box, Stack } from "@mui/system";
import { SubmitButton } from "../common/components/SubmitButton";
import { ErrorNotification } from "../common/components/ErrorNotification";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import type {
  BusinessListApplicationSubmission,
  BusinessOwner,
} from "./BusinessListApplicationSubmission";
import { submitBusinesListApplication } from "../common/api/businessListApplicationApi";
import { BusinessListApplicationHeader } from "./BusinessListApplicationHeader";
import Typography from "@mui/material/Typography";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import { CanadaAddressFields } from "../common/components/FormFields/CanadaAddressFields";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FieldArray } from "react-final-form-arrays";
import Button from "@mui/material/Button";
import { BusinessOwnerFields } from "./BusinessOwnerFields";
import arrayMutators from "final-form-arrays";
import NotesIcon from "@mui/icons-material/Notes";
import { BusinessListApplicationConsentFields } from "./BusinessListApplicationConsentFields";
import { BusinessListApplicationFilesUpload } from "./BusinessListApplicationFilesUpload";
import { Helmet } from "react-helmet";

const RECAPTCHA_ACTION = "SubmitBusinessListApplicationForm";

const DEFAULT_BUSINESS_OWNER_FIELDS: BusinessOwner = {
  nameOfOwner: "",
  percentOwnership: "",
  inuvialuitBeneficiary: false,
};

const initialValues: BusinessListApplicationSubmission = {
  allServicesProvided: "",
  applicantName: "",
  businessAddress: {
    country: "Canada",
  },
  businessCell: "",
  businessEmail: "",
  businessFax: "",
  businessName: "",
  businessOwners: [DEFAULT_BUSINESS_OWNER_FIELDS],
  businessPartnerName: "",
  businessPhone: "",
  businessStructure: undefined,
  businessDocuments: [],
  businessTollFree: "",
  businessWebsite: "",
  contactFirstName: "",
  contactLastName: "",
  contactPosition: "",
  incorporationDate: "",
  majorEquipmentAndSpace: "",
  positionsAtBusiness: "",
  registrationDate: "",
  consentAccept: false,
  releaseAccept: false,
};

interface BusinessListApplicationFormProps {}

export function BusinessListApplicationForm({}: BusinessListApplicationFormProps) {
  const navigate = useNavigate();
  const recaptchaReady = useRecaptchaReady();
  const [showError, setShowError] = useState(false);

  return (
    <>
      <Helmet>
        <title>Business List Application Form</title>
      </Helmet>
      <Form<BusinessListApplicationSubmission>
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        subscription={{ invalid: true, submitting: true }}
        onSubmit={async (application) => {
          try {
            const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
            const token = await grecaptcha.enterprise.execute(clientKey, {
              action: RECAPTCHA_ACTION,
            });
            await submitBusinesListApplication(
              application,
              token,
              RECAPTCHA_ACTION
            );
            navigate("/success");
          } catch {
            setShowError(true);
          }
        }}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <BusinessListApplicationHeader />

            <Stack gap={2}>
              <Box>
                <Typography variant="h6">
                  Applicant Contact Information
                </Typography>
              </Box>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="applicantName"
                    label="Applicant's Name"
                    required
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="businessPartnerName"
                    label="Business Partner Name(s)"
                    required
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="businessName"
                    label="Business Name"
                    required
                    icon={<BusinessCenterIcon />}
                  />
                </Grid>
              </Grid>

              <Box>
                <Typography variant="h6">Business Contact</Typography>
              </Box>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="contactFirstName"
                    label="First Name"
                    required
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="contactLastName"
                    label="Last Name"
                    required
                    icon={<MuiPerson />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="contactPosition"
                    label="Position Held in Business"
                    required
                    icon={<BadgeIcon />}
                  />
                </Grid>
              </Grid>

              <Box>
                <Typography variant={"h6"}>Business Address</Typography>
              </Box>
              <CanadaAddressFields namespace={"businessAddress"} required />

              <Box>
                <Typography variant="h6">Phone/Fax/Email</Typography>
              </Box>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="businessPhone"
                    label="Phone"
                    required
                    icon={<LocalPhoneIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="businessFax"
                    label="Fax"
                    required
                    icon={<FaxIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="businessTollFree"
                    label="Toll-Free"
                    required
                    icon={<LocalPhoneIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFormField
                    name="businessCell"
                    label="Cell"
                    required
                    icon={<LocalPhoneIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="businessEmail"
                    label="Email"
                    required
                    icon={<EmailIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="businessWebsite"
                    label="Website"
                    required
                    icon={<LinkIcon />}
                  />
                </Grid>
              </Grid>

              <Box>
                <Typography variant="h6">
                  Ownership & Structure of Business
                </Typography>
              </Box>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  <RadioFormField
                    name={"businessStructure"}
                    options={[
                      "Sole Proprietorship",
                      "Partnership",
                      "Corporation",
                      "Co-op",
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateFormField
                    name={"registrationDate"}
                    label={"Registration Date"}
                    stringOutput
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateFormField
                    name={"incorporationDate"}
                    label={"Incorporation Date"}
                    stringOutput
                  />
                </Grid>
              </Grid>

              <Box>
                <Typography variant={"h6"}>Business Owners</Typography>
              </Box>
              <FieldArray<Partial<BusinessOwner>>
                name="businessOwners"
                render={({ fields }) => (
                  <Stack gap={1}>
                    {fields.map((name, index) => (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <BusinessOwnerFields namespace={name} />
                        <IconButton
                          color="error"
                          onClick={() => fields.remove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    ))}
                    <Box
                      ml={5}
                      flex={1}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={() => {
                          if ((fields?.length ?? 0) >= 10) {
                            setShowError(true);
                          } else {
                            fields.push({ ...DEFAULT_BUSINESS_OWNER_FIELDS });
                          }
                        }}
                      >
                        Add Business Owner
                      </Button>
                    </Box>
                  </Stack>
                )}
              />

              <Box>
                <Typography variant={"h6"}>Business Details</Typography>
              </Box>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  <TextFormField
                    name={"allServicesProvided"}
                    multiline
                    required
                    icon={<NotesIcon />}
                    label={
                      "Describe all of the services the Business provides."
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name={"majorEquipmentAndSpace"}
                    multiline
                    required
                    icon={<NotesIcon />}
                    label={
                      "Describe the major equipment and/or space used in the Business."
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name={"positionsAtBusiness"}
                    multiline
                    required
                    icon={<NotesIcon />}
                    label={
                      "Describe all positions employed in the Business, including job title and brief outline of duties."
                    }
                  />
                </Grid>
              </Grid>

              <Box>
                <Typography variant={"h6"}>Business Documents</Typography>
              </Box>
              <BusinessListApplicationFilesUpload />
              <BusinessListApplicationConsentFields />

              <Box ml={5}>
                <SubmitButton
                  type="submit"
                  disabled={invalid || !recaptchaReady}
                  isSubmitting={submitting}
                />
              </Box>
            </Stack>
          </form>
        )}
      />

      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}
