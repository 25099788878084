import { apiResponse } from "./Interfaces/response";
import { sendFormDataPost } from "../networking";
import type { BusinessListApplicationSubmission } from "../../BusinessListApplicationForm/BusinessListApplicationSubmission";
import { serialize } from "object-to-formdata";

export async function submitBusinesListApplication(
  application: BusinessListApplicationSubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { ...application, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost("/api/business-list-application", formData);
}
