import { FileFormField } from "../common/components/FormFields";
import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

interface BusinessListApplicationFilesUploadProps {}

export function BusinessListApplicationFilesUpload({}: BusinessListApplicationFilesUploadProps) {
  return (
    <>
      <Box>
        <Typography paragraph>
          The following documentation must be provided:
        </Typography>
        <ol>
          <li>
            <Typography paragraph>
              Copy of current{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Business License
              </Typography>
              .
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              If the business is a{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Partnership
              </Typography>
              , a copy of a{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Partnership Agreement
              </Typography>{" "}
              outling the percentage of ownership and distribution of earnings.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              If the business is a{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Corporation
              </Typography>
              , a copy of{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Incorporation Certificate
              </Typography>
              ,{" "}
              <Typography
                fontStyle={"italic"}
                fontWeight={"bold"}
                display={"inline"}
              >
                Share Registry
              </Typography>{" "}
              and any shareholders agreement outlining participating rights.
            </Typography>
          </li>
        </ol>
        <FileFormField name="businessDocuments" multiFile noIcon required />
      </Box>
    </>
  );
}
