import { MuiPerson } from "../common/icons";
import PercentIcon from "@mui/icons-material/Percent";
import {
  CheckboxFormField,
  TextFormField,
} from "../common/components/FormFields";
import React from "react";
import Grid from "@mui/material/Grid";

interface BusinessOwnerFieldsProps {
  namespace: string;
}

export function BusinessOwnerFields({ namespace }: BusinessOwnerFieldsProps) {
  return (
    <>
      <Grid
        container
        spacing={2}
        mb={2}
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          <TextFormField
            name={`${namespace}.nameOfOwner`}
            label="Name of Owner"
            required
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextFormField
            name={`${namespace}.percentOwnership`}
            label="% Ownership"
            required
            icon={<PercentIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CheckboxFormField
            name={`${namespace}.inuvialuitBeneficiary`}
            label={"Inuvialuit Beneficiary?"}
          />
        </Grid>
      </Grid>
    </>
  );
}
